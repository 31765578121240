<template>
  <section class="container">
    <div class="home-directions margin__lg">
      <h2 class="text__center">Here to Help</h2>
      <p class="text__center text__md padding__sm">
        Welcome to the Flat Branch family. We're dedicated to providing you with the best service possible,
        whether in person, online or by phone. If you need help or can't find something you are looking for,
        please feel free to contact us!
      </p>
      <div class="flex__center">
        <router-link to="/contact" class="btn btn__orange btn__lg">Contact Us</router-link>
      </div>
      <div class="directions-grid margin__md">
        <div class="direction-item" v-scroll-reveal="first">
          <h3 class="text__center">Frequently Asked</h3>
          <p class="text__center">You have questions, we have answers.</p>
          <div class="flex__center">
            <router-link to="/faq" class="btn btn__green btn__sm">Read FAQ</router-link>
          </div>
        </div>
        <div class="direction-item" v-scroll-reveal="second">
          <h3 class="text__center">Payment Assistance</h3>
          <p class="text__center">Sometimes life throws you a curveball. We have solutions.</p>
          <div class="flex__center">
            <router-link to="/payment-assistance" class="btn btn__green btn__sm">Assistance Options</router-link>
          </div>
        </div>
        <div class="direction-item" v-scroll-reveal="third">
          <h3 class="text__center">Payment Options</h3>
          <p class="text__center">We have a variety of payment options to make your life easier</p>
          <div class="flex__center">
            <router-link to="/payment-options" class="btn btn__green btn__sm">Payment Options</router-link>
          </div>
        </div>
        <div class="direction-item" v-scroll-reveal="fourth">
          <h3 class="text__center">New Customers</h3>
          <p
            class="text__center"
          >We are here to help you with signing in, registering, and learning the process.</p>
          <div class="flex__center">
            <router-link to="/new-customers" class="btn btn__green btn__sm">Start Here</router-link>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  data() {
    return {
      first: {
        delay: 100,
        distance: "100px",
        duration: "1000",
        opacity: 0,
        origin: "bottom"
      },
      second: {
        delay: 100,
        distance: "100px",
        duration: "1000",
        opacity: 0,
        origin: "bottom"
      },
      third: {
        delay: 100,
        distance: "100px",
        duration: "1000",
        opacity: 0,
        origin: "bottom"
      },
      fourth: {
        delay: 100,
        distance: "100px",
        duration: "1000",
        opacity: 0,
        origin: "bottom"
      }
    };
  },
  created() {
    if (window.innerWidth >= 768) {
      this.first.delay = 100;
      this.second.delay = 300;
      this.third.delay = 500;
      this.fourth.delay = 700;
    }
  }
};
</script>

<style scoped lang="scss">
h2 {
  font-size: 2.4rem;
}
.directions-grid {
  @include flex($just: center, $align: center, $wrap: wrap);
}
.direction-item {
  border-radius: 5px;
  padding: 10px 20px;
  margin: 15px;
  @include grid($cols: 1fr, $row-gap: 10px, $align: center);
  width: 100%;
  max-width: 250px;
  height: 300px;
  background-color: lighten($pistachio, 10%);
  h3 {
    font-size: 1.8rem;
  }
  h3,
  p {
    color: $black;
  }
}
</style>