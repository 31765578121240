<template>
  <footer>
    <FooterSm class="footer-sm" />
    <FooterMd class="footer-md" />
  </footer>
</template>

<script>
import FooterSm from "./FooterSm";
import FooterMd from "./FooterMd";
export default {
  components: {
    FooterSm,
    FooterMd
  }
};
</script>