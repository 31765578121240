<template>
  <section>
    <Banner />
    <section class="container">
      <section class="margin__md">
        <h2 class="padding__sm">We're here to help!</h2>
        <p>
          You may be eligible to refinance or modify your Flat Branch Mortgage, Inc. home loan so your terms and monthly payments are more manageable.
          Or, if you have missed a few payments on your Flat Branch Mortgage, Inc. loan, you may qualify for a temporary or permanent solution to help you get your finances back on track.
          Our Mortgage Default Team specializes in member counseling and can help put you back in control.
        </p>
        <br />
        <p class="pb-15">
          If you're having trouble paying your mortgage, give us a call at
          <a
            href="tel: 877-350-0350"
          >(877) 350-0350</a> or email us at
          <a href="mailto: lossmitigation@fbhl.com">lossmitigation@fbhl.com</a>.
          You can also contact the Homeowner's HOPE&trade; Hotline.
          This hotline provides free, comprehensive foreclosure assistance and housing counseling services around-the-clock.
          Call
          <a
            href="tel: 1-888-995-4673"
          >1-888-995-HOPE(4673)</a> (Hearing impaired:
          <a href="tel: 1-877-304-9709">1-877-304-9709</a> TTY) to ask about receiving help with your mortgage and to see if you qualify for the Making Home Affordable&reg; Program.
        </p>
      </section>
    </section>
    <Options />
    <Instructions />
    <Scam />
  </section>
</template>

<script>
import Banner from "./Banner";
import Options from "./Options";
import Instructions from "./Instructions";
import Scam from "./Scam";
export default {
  metaInfo: {
    title: "Payment Assistance | Flat Branch Servicing"
  },
  components: {
    Banner,
    Options,
    Instructions,
    Scam
  }
};
</script>
