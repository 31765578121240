<template>
  <div class="qa">
    <div class="question flex__between pointer" @click="toggle">
      <p class="text__bold">{{ question }}</p>
      <div class="icon">
        <font-awesome-icon :icon="iconClass" size="1x" class="ml-10" />
      </div>
    </div>
    <div class="answer" :class="toggleClass">
      <div v-html="answer"></div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["question", "answer"],
  data() {
    return {
      toggled: false
    };
  },
  computed: {
    iconClass() {
      return this.toggled ? "caret-up" : "caret-down";
    },
    toggleClass() {
      return this.toggled ? "answer-slide" : null;
    }
  },
  methods: {
    toggle() {
      this.toggled = !this.toggled;
    }
  }
};
</script>

<style lang="scss">
.qa {
  margin: 5px 0;
}
.answer {
  overflow: hidden;
  @include grid($cols: 1fr, $row-gap: 2px);
  height: auto;
  max-height: 0;
  transition: all 0.35s linear;
}
.question {
  background-color: $frost;
  padding: 10px 5px;
  color: $green;
  border: 1px solid $cloud;
  border-radius: 3px;
  .icon {
    color: $black;
  }
  &:hover {
    background-color: lighten($frost, 5%);
  }
}
.answer {
  > div,
  > p {
    padding: 20px 15px;
    p {
      margin-bottom: 8px;
    }
    ul {
      padding-left: 15px;
      li {
        margin-bottom: 5px;
      }
    }
  }
  background-color: lighten($cloud, 15%);
  border-radius: 3px;
}
.answer-slide {
  max-height: 1000px;
}
</style>