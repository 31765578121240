<template>
  <section>
    <Banner />
    <Directions />
    <Register />
  </section>
</template>

<script>
import Banner from "./Banner";
import Directions from "./Directions";
import Register from "./Register";
export default {
  metaInfo: {
    title: "New Customers | Flat Branch Servicing"
  },
  components: {
    Banner,
    Directions,
    Register
  }
};
</script>

<style scoped lang="scss">
section {
  scroll-behavior: smooth;
}
</style>