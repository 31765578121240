<template>
  <section>
    <Banner />
    <Directions />
    <GoGreen />
    <MobileApp />
  </section>
</template>

<script>
import Banner from "./Banner";
import Directions from "./Directions";
import GoGreen from "./GoGreen";
import MobileApp from "./MobileApp";
export default {
  metaInfo: {
    title: "Flat Branch Servicing | Flat Branch Servicing"
  },
  components: {
    Banner,
    Directions,
    GoGreen,
    MobileApp
  }
};
</script>

<style scoped lang="scss">
</style>