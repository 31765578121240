<template>
  <section>
    <Options />
    <PayEarn />
  </section>
</template>

<script>
import Options from "./Options";
import PayEarn from "./PayEarn";
export default {
  metaInfo: {
    title: "Payment Options | Flat Branch Servicing"
  },
  components: {
    Options,
    PayEarn
  }
};
</script>

<style scoped lang="scss">
</style>