<template>
  <section class="modal flex__center" @click.self="startSetPopupToggled(false)">
    <section class="modal-tooltip pos__rel">
      <p class="top-left" @click="startSetPopupToggled(false)">
        <span class="text__green text__bold">
          <font-awesome-icon icon="arrow-left" size="sm" class="mr-10" />Return
        </span>
      </p>
      <p class="text__center">Servicing digital will be available July 6th, 2021 at 5:00 pm CST</p>
      <p class="text__center">Thank you for your patience</p>
    </section>
  </section>
</template>

<script>
import { mapActions } from "vuex";
export default {
  methods: {
    ...mapActions(["startSetPopupToggled"])
  }
};
</script>

<style scoped lang="scss">
.modal-tooltip {
  background-color: #fff;
  padding: 50px;
  border-radius: 14px;
  max-width: 350px;
  @include grid($cols: 1fr, $row-gap: 8px);
}
.top-left {
  @include pos-abs($top: 15px, $left: 15px);
}
</style>