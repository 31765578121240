import Vue from "vue";
import VueRouter from "vue-router";
import Pages from "../components/Pages";

Vue.use(VueRouter);

export default new VueRouter({
   mode: "history",
   scrollBehavior(to, from, savedPosition) {
      if (savedPosition) {
         return savedPosition;
      }
      if (to.hash) {
         return {
            selector: to.hash,
            bahavior: 'smooth'
         }
      }
      return {
         x: 0,
         y: 0,
         behavior: 'smooth'
      }
   },
   routes: [
      {
         path: "/",
         name: "home",
         component: Pages.Home
      },
      {
         path: "/new-customers",
         name: "new-customers",
         component: Pages.NewCust
      },
      {
         path: "/payment-options",
         name: "payment-options",
         component: Pages.PaymentOpts
      },
      {
         path: "/payment-assistance",
         name: "payment-assistance",
         component: Pages.PaymentAsst
      },
      {
         path: "/forms",
         name: "forms",
         component: Pages.Forms
      },
      {
         path: "/faq",
         name: "faq",
         component: Pages.FAQ
      },
      {
         path: "/contact",
         name: "contact",
         component: Pages.Contact
      },
      {
         path: "/fair-credit-ra",
         name: "fair-credit-ra",
         component: Pages.FCRA
      },
      {
         path: "/covid-19",
         name: "covid-19",
         component: Pages.Covid
      },
      // {
      //    path: "/system-faq",
      //    name: "system-faq",
      //    component: Pages.SystemFAQ
      // },
      {
         path: "/e-sign-disclosure",
         name: "e-sign",
         component: Pages.ESign
      },
      {
         path: "/edeliver-1098",
         name: "edeliver-1098",
         component: Pages.EDeliver1098
      },
      {
         path: "/recurring-ach-authorization",
         name: "recur-ach",
         component: Pages.RecurACH
      },
      {
         path: "/one-time-ach-authorization",
         name: "one-time-ach",
         component: Pages.OneTimeACH
      }
   ]
});
