<template>
  <div class="mobile-nav">
    <div class="mobile-icon" @click="startSetMenuToggled(true)">
      <font-awesome-icon icon="bars" size="3x" />
    </div>
    <transition name="menu">
      <div v-if="getMenuToggled" class="modal" @click.self="startSetMenuToggled(false)">
        <div class="mobile-nav--list">
          <div class="flex__between">
            <div class="flex__center">
              <a
                href="https://loansphereservicingdigital.bkiconnect.com/fbhl"
                rel="noreferrer nofollow external"
                target="blank"
                name="Servicing digital webpage"
                class="btn btn__sm btn__orange"
              >Make a Payment</a>
            </div>
            <div class="mobile-icon flex__center text__bold" @click="startSetMenuToggled(false)">
              Back
              <font-awesome-icon icon="arrow-right" size="1x" class="ml-10" />
            </div>
          </div>
          <NavList />
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import NavList from "./NavList";
export default {
  components: {
    NavList
  },
  computed: {
    ...mapGetters(["getMenuToggled"])
  },
  methods: {
    ...mapActions(["startSetMenuToggled"])
  }
};
</script>

<style scoped lang="scss">
.mobile-icon {
  color: $green;
}
.mobile-nav--list {
  @include pos-abs(
    $pos: fixed,
    $left: null,
    $right: 0,
    $height: 100vh,
    $width: 100vw,
    $z: 5
  );
  min-width: 200px;
  max-width: 300px;
  background-color: $frost;
}
.mobile-nav--list .flex__between {
  padding: 15px 0;
  margin: 0 15px;
  .mobile-icon {
    color: $orange;
  }
}
@media (min-width: $lg) {
  .mobile-nav {
    display: none;
  }
}
</style>