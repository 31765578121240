<template>
  <div>
    <div class="btn btn__green btn__large footer-btn" @click="toggle">
      Need Help?
      <font-awesome-icon :icon="icon" size="1x" class="ml-10" />
    </div>
    <div class="footer-slide__grid" :class="toggleClass">
      <router-link to="/forms">Forms</router-link>
      <router-link to="/faq">FAQ</router-link>
      <router-link to="/contact">Contact Us</router-link>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isToggled: false
    };
  },
  computed: {
    icon() {
      return this.isToggled ? "caret-up" : "caret-down";
    },
    toggleClass() {
      return this.isToggled ? "footer-slide" : null;
    }
  },
  methods: {
    toggle() {
      return (this.isToggled = !this.isToggled);
    }
  }
};
</script>

<style scoped lang="scss">
</style>