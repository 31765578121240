<template>
  <div class="container">
    <div class="padding__sm">
      <div class="flex__between padding__md">
        <img
          src="https://cdn.fbhl.dev/servicing/images/fbhl-text-white-left.png"
          alt="Flat Branch logo"
          width="250px"
          height="60px"
        />
        <router-link to="/new-customers" class="btn btn__orange">
          New Customers
          <font-awesome-icon icon="arrow-right" size="sm" class="ml-10" />
        </router-link>
      </div>
      <div class="link__grid">
        <div class="link__grid-group">
          <p>Payments</p>
          <a
            href="https://loansphereservicingdigital.bkiconnect.com/fbhl"
            name="Servicing digital web page"
            rel="noreferrer"
            target="blank"
          >Make a Payment</a>
          <router-link to="/payment-options">Payment Options</router-link>
          <router-link to="/payment-assistance">Payment Assistance</router-link>
          <a
            href="https://form.jotform.com/203325480807150#open"
            rel="noreferrer"
            name="Payoff request form"
            target="_blank"
          >Payoff Request Form</a>
        </div>
        <div class="link__grid-group">
          <p>Need Help?</p>
          <router-link to="/forms">Forms</router-link>
          <router-link to="/faq">FAQ</router-link>
          <router-link to="/contact">Contact Us</router-link>
        </div>
        <div class="link__grid-group">
          <p>Social</p>
          <div class="social-links">
            <a
              href="https://www.facebook.com/flatbranchhomeloans/"
              name="FBHL Facebook"
              target="_blank"
              rel="noreferrer"
            >
              <font-awesome-icon :icon="['fab', 'facebook-square']" size="2x" />
            </a>
            <a
              href="https://twitter.com/FlatBranchLoans"
              name="FBHL Twitter"
              target="_blank"
              rel="noreferrer"
            >
              <font-awesome-icon :icon="['fab', 'twitter']" size="2x" />
            </a>
            <a
              href="https://www.linkedin.com/company/flat-branch-home-loans"
              name="FBHL LinkedIn"
              target="_blank"
              rel="noreferrer"
            >
              <font-awesome-icon :icon="['fab', 'linkedin']" size="2x" />
            </a>
            <a
              href="https://www.instagram.com/flatbranchhomeloans/"
              name="FBHL Instagram"
              target="_blank"
              rel="noreferrer"
            >
              <font-awesome-icon :icon="['fab', 'instagram']" size="2x" />
            </a>
          </div>
        </div>
      </div>
      <div class="legal-links flex__center flex__wrap">
        <a
          href="https://flatbranchhomeloans.com/privacy"
          target="_blank"
          name="Read Flat Branch Privacy Policy"
        >Privacy Policy</a>
        <a
          href="https://flatbranchhomeloans.com/licenses"
          target="_blank"
          name="Learn about Flat Branch licensing"
        >Licenses</a>
        <router-link to="fair-credit-ra">Fair Credit RA</router-link>
      </div>
      <div class="flex__center padding__sm">
        <p class="text__center text__white">140 Long Road Suite 200 Chesterfield, MO 63005</p>
      </div>
      <NMLS />
    </div>
  </div>
</template>

<script>
import NMLS from "./NMLS";
export default {
  components: {
    NMLS
  }
};
</script>

<style scoped lang="scss">
.link__grid {
  @include grid($cols: 1fr 1fr 1fr, $col-gap: 20px, $align: start);
}
.link__grid-group {
  @include grid($cols: 1fr, $row-gap: 5px);
  p {
    font-weight: bold;
    letter-spacing: 1px;
  }
  * {
    color: #fff;
  }
  a {
    color: $pistachio;
    text-decoration: none;
  }
}
.social-links {
  @include grid($cols: 1fr 1fr, $rows: 1fr 1fr, $row-gap: 8px, $col-gap: 5px);
}
</style>
