<template>
  <section class="container">
    <div class="padding__sm">
      <h2 class="padding__sm">Billing & Payment Processing</h2>
      <Link
        name="ACH Authorization Form"
        content="Complete form to commence automatic payment withdrawal from your checking or savings account"
        link="https://cdn.fbhl.dev/servicing/files/ach_enrollment_form_and_faqs_fillable.pdf"
      />
      <Link
        name="ACH and Flex Draft Change Form"
        content="Complete form to change your existing automatic payment withdrawal"
        link="https://cdn.fbhl.dev/servicing/files/ach_and_flex_draft_change_form_fillable.pdf"
      />
      <Link
        name="Flex Draft Authorization Form"
        content="Complete form to commence flexible payment withdrawal from your checking or savings account"
        link="https://cdn.fbhl.dev/servicing/files/flex_draft_enrollment_and_faqs_fillable.pdf"
      />
      <Link
        name="Fee and Cost Schedule"
        content="Comprehensive list of fees and costs regarding loan servicing"
        link="https://cdn.fbhl.dev/servicing/files/fee-and-cost-schedule.pdf"
      />
      <h2 class="padding__sm">Escrow</h2>
      <Link
        name="Escrow Waiver Request"
        content="Complete form to request the discontinuance of your Escrow Account"
        link="https://cdn.fbhl.dev/servicing/files/escrow_waiver_request.pdf"
      />
      <Link
        name="Recast Request"
        content="Complete form to request a recast on your loan"
        link="https://cdn.fbhl.dev/servicing/files/recast_request.pdf"
      />
      <h2 class="padding__sm">Insurance Claims</h2>
      <Link
        name="Customer Insurance Loss Draft - USDA Loans"
        content="Complete form and send with insurance check regarding any loss to your property"
        link="https://cdn.fbhl.dev/servicing/files/loss_draft_form-usda.pdf"
      />
      <Link
        name="Customer Insurance Loss Draft - Other Loans"
        content="Complete form and send with insurance check regarding any loss to your property"
        link="https://cdn.fbhl.dev/servicing/files/loss_draft_form-other.pdf"
      />
      <h2 class="padding__sm">Payment Assistance</h2>
      <Link
        name="4506-C"
        content="Complete along with Borrower Assistance Package to request financial assistance with your loan."
        link="https://cdn.fbhl.dev/servicing/files/4506c.pdf"
      />
      <Link
        name="Borrower Assistance Package"
        content="Complete along with 4506-C to request financial assistance with your loan."
        link="https://cdn.fbhl.dev/servicing/files/borrower_assistance_packet.pdf"
      />
      <h2 class="padding__sm">SCRA</h2>
      <Link
        name="Active Military Duty"
        content="Complete form to advise Flat Branch Mortgage, Inc. of military deployment"
        link="https://cdn.fbhl.dev/servicing/files/active-military-duty-7-9-20.pdf"
      />
      <h2 class="padding__sm">General Account Information</h2>
      <Link
        name="Borrower Authorization for Third Party Form"
        content="Complete form to allow an additional party access to your loan information"
        link="https://cdn.fbhl.dev/servicing/files/borrower_third_party_auth_form.pdf"
      />
      <Link
        name="Change of Information Form"
        content="Complete form for change of address, phone number, social security number or email address"
        link="https://cdn.fbhl.dev/servicing/files/change_of_information.pdf"
      />
      <Link
        name="eSign Consent"
        content="Complete form to consent to the digital delivery of forms"
        link="https://cdn.fbhl.dev/servicing/files/esign-consent.pdf"
      />
    </div>
  </section>
</template>

<script>
import Link from "./Link";
export default {
  metaInfo: {
    title: "Forms | Flat Branch Servicing"
  },
  components: {
    Link
  }
};
</script>

<style scoped lang="scss">
</style>
