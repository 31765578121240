<template>
  <section class="margin__lg">
    <section class="container">
      <div class="flex__between margin__sm">
        <h2>Servicing Digital</h2>
        <a href="#register" v-smooth-scroll class="btn btn__sm btn__orange ml-10">Register</a>
      </div>
      <div class="margin__sm">
        <p class="text__lg">
          <i>Servicing Digital</i> is your online portal for managing your home loan!
          <i>Servicing Digital</i> is free and available 24 hours a day, 7 days a week and has many benefits.
          <!-- By registering for <i>Servicing Digital</i> you'll be able to: -->
        </p>
      </div>
      <div class="flex__around flex__wrap margin__md">
        <div class="tile flex__col" v-scroll-reveal="first">
          <p class="text__bold text__center">Manage Finances</p>
          <ul>
            <li>Make payments online</li>
            <li>Check current loan balances</li>
            <li>See payment due dates</li>
          </ul>
        </div>
        <div class="tile flex__col" v-scroll-reveal="second">
          <p class="text__bold text__center">Full Access</p>
          <ul>
            <li>12-month payment history</li>
            <li>Year-to-date totals</li>
            <li>Mortgage statements</li>
          </ul>
        </div>
        <div class="tile flex__col" v-scroll-reveal="third">
          <p class="text__bold text__center">Mobile Access</p>
          <div>
            <p class="text__center text__regular">Log in to our mobile app</p>
            <div class="flex__col flex__center">
              <a
                href="hhttps://apps.apple.com/us/app/flat-branch-servicing/id1566902327"
                name="Apple app store web page"
                target="_blank"
                rel="noreferrer nofollow external"
              >
                <img
                  src="https://cdn.fbhl.dev/servicing/images/apple_final.png"
                  alt="apple app store icon"
                  width="153px"
                  height="46px"
                />
              </a>
              <a
                href="https://play.google.com/store/apps/details?id=com.flatbranchhomeloans.servicing"
                name="Google Play store web page"
                target="_blank"
                rel="noreferrer nofollow external"
              >
                <img
                  src="https://cdn.fbhl.dev/servicing/images/google_final.png"
                  alt="google play store button"
                  width="153px"
                  height="46px"
                />
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  </section>
</template>

<script>
export default {
  data() {
    return {
      first: {
        delay: 100,
        distance: "100px",
        duration: "1000",
        opacity: 0,
        origin: "bottom"
      },
      second: {
        delay: 100,
        distance: "100px",
        duration: "1000",
        opacity: 0,
        origin: "bottom"
      },
      third: {
        delay: 100,
        distance: "100px",
        duration: "1000",
        opacity: 0,
        origin: "bottom"
      }
    };
  },
  created() {
    if (window.innerWidth >= 768) {
      this.first.delay = 100;
      this.second.delay = 300;
      this.third.delay = 500;
    }
  }
};
</script>

<style scoped lang="scss">
.tile {
  margin: 10px;
  background-color: $frost;
  width: 100%;
  max-width: 300px;
  height: 300px;
  padding: 20px;
  border-radius: 5px;
  @include grid($cols: 1fr, $rows: 30% 70%, $align: center);
  p {
    font-size: 1.4rem;
  }
  .text__regular {
    font-size: $text-regular;
    margin-bottom: 8px;
  }
  ul {
    @include grid($cols: 1fr, $row-gap: 5px);
    list-style: disc;
    list-style-position: inside;
  }
}
</style>