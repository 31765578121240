<template>
  <div class="container">
    <div class="padding__sm">
      <div class="flex__center padding__sm">
        <img
          src="https://cdn.fbhl.dev/servicing/images/fbhl-text-white-left.png"
          alt="Flat Branch logo"
          width="250px"
          height="60px"
        />
      </div>
      <div class="btn__grid">
        <router-link to="/new-customers" class="btn btn__orange">
          New Customers
          <font-awesome-icon icon="arrow-right" size="sm" class="ml-10" />
        </router-link>
        <Payments />
        <Help />
        <div class="social-links flex__around">
          <a
            href="https://www.facebook.com/flatbranchhomeloans/"
            name="FBHL Facebook"
            target="_blank"
            rel="noreferrer"
          >
            <font-awesome-icon :icon="['fab', 'facebook-square']" size="2x" />
          </a>
          <a
            href="https://twitter.com/FlatBranchLoans"
            name="FBHL Twitter"
            target="_blank"
            rel="noreferrer"
          >
            <font-awesome-icon :icon="['fab', 'twitter']" size="2x" />
          </a>
          <a
            href="https://www.linkedin.com/company/flat-branch-home-loans"
            name="FBHL LinkedIn"
            target="_blank"
            rel="noreferrer"
          >
            <font-awesome-icon :icon="['fab', 'linkedin']" size="2x" />
          </a>
          <a
            href="https://www.instagram.com/flatbranchhomeloans/"
            name="FBHL Instagram"
            target="_blank"
            rel="noreferrer"
          >
            <font-awesome-icon :icon="['fab', 'instagram']" size="2x" />
          </a>
        </div>
        <div class="legal-links flex__center flex__wrap">
          <a
            href="https://flatbranchhomeloans.com/privacy"
            target="_blank"
            name="Read Flat Branch Privacy Policy"
          >Privacy Policy</a>
          <a
            href="https://flatbranchhomeloans.com/licenses"
            target="_blank"
            name="Learn about Flat Branch licensing"
          >Licenses</a>
          <router-link to="fair-credit-ra">Fair Credit RA</router-link>
        </div>
        <div class="padding__sm flex__center">
          <p class="text__center text__white">
            140 Long Road Suite 200
            <br />Chesterfield, MO 63005
            <br />
          </p>
        </div>
      </div>
      <NMLS />
    </div>
  </div>
</template>

<script>
import Payments from "./Payments";
import Help from "./Help";
import NMLS from "./NMLS";
export default {
  components: {
    Payments,
    Help,
    NMLS
  }
};
</script>

<style scoped lang="scss">
.btn__grid {
  @include grid($cols: 1fr, $row-gap: 10px);
  .btn {
    @include flex($just: space-between, $align: center);
  }
}
.social-links {
  padding-top: 15px;
  * {
    color: #fff;
  }
}
</style>