<template>
  <nav>
    <div class="bg-link pos__rel">
      <router-link
        to="/new-customers"
        class="nav-link"
        @click.native="startSetMenuToggled(false)"
      >New Customers</router-link>
      <div class="hover-link"></div>
    </div>
    <div class="bg-link pos__rel">
      <router-link
        to="/payment-options"
        class="nav-link"
        @click.native="startSetMenuToggled(false)"
      >Payment Options</router-link>
      <div class="hover-link"></div>
    </div>
    <div class="bg-link pos__rel">
      <router-link
        to="/payment-assistance"
        class="nav-link"
        @click.native="startSetMenuToggled(false)"
      >Payment Assistance</router-link>
      <div class="hover-link"></div>
    </div>
    <div class="bg-link pos__rel">
      <router-link to="/forms" class="nav-link" @click.native="startSetMenuToggled(false)">Forms</router-link>
      <div class="hover-link"></div>
    </div>
    <div class="bg-link pos__rel">
      <router-link to="/faq" class="nav-link" @click.native="startSetMenuToggled(false)">FAQ</router-link>
      <div class="hover-link"></div>
    </div>
    <div class="bg-link pos__rel">
      <router-link
        to="/contact"
        class="nav-link"
        @click.native="startSetMenuToggled(false)"
      >Contact Us</router-link>
      <div class="hover-link"></div>
    </div>
  </nav>
</template>

<script>
import { mapActions } from "vuex";
export default {
  methods: {
    ...mapActions(["startSetMenuToggled"])
  }
};
</script>

<style scoped lang="scss">
nav {
  @include grid($cols: 1fr, $row-gap: 2px);
  background: linear-gradient(to right, $frost, $green, $frost);
  padding: 2px 0;
}
.router-link-active {
  font-weight: 600;
  color: $orange !important;
}
.nav-link {
  font-size: $text-lg;
  text-align: center;
  @include flex($just: center, $align: center);
  padding: 25px 0;
  background-color: $frost;
  text-decoration: none;
  color: $black;
}
.nav-link:hover {
  background-color: $powder;
}
.hover-link {
  @include pos-abs($pos: absolute, $top: 80%, $width: 0%, $height: 2px, $z: 15);
}
@media (min-width: $lg) {
  nav {
    @include flex($just: space-between, $align: center);
    background: #fff;
  }
  .nav-link {
    font-size: $text-md;
    padding: 5px 0;
    background-color: #fff;
  }
  .nav-link:hover {
    background-color: #fff;
  }
  .bg-link {
    padding-top: 2px;
    .hover-link {
      transition: all 0.35s ease-in-out;
      width: 0%;
    }
  }
  .bg-link:hover .hover-link {
    width: 100%;
    background-color: $pistachio;
  }
}
</style>