<template>
  <header>
    <transition name="slide-up">
      <Notification />
    </transition>
    <div class="container">
      <div class="flex__between">
        <router-link to="/" class="link__none home-link">
          <img
            src="https://cdn.fbhl.dev/servicing/images/fbhl-home-svg.svg"
            alt="FBHL Logo"
            width="100px"
            height="100px"
          />
        </router-link>
        <a
          href="https://loansphereservicingdigital.bkiconnect.com/fbhl"
          rel="noreferrer external nofollow"
          name="Servicing digital webpage"
          class="btn btn__sm btn__orange"
        >Make a Payment</a>
        <Mobile />
      </div>
      <Standard />
    </div>
  </header>
</template>

<script>
import Notification from "./Notification";
import Mobile from "./Nav/Mobile";
import Standard from "./Nav/Standard";
export default {
  components: {
    Notification,
    Mobile,
    Standard
  }
};
</script>

<style scoped lang="scss">
header {
  position: sticky;
  top: 0;
  background-color: #fff;
  z-index: 10;
}
.flex__between .btn {
  display: none;
}
@media (min-width: $lg) {
  .flex__between .btn {
    display: block;
  }
}
</style>