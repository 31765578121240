<template>
  <section class="padding__md contact-banner">
    <section class="container">
      <div class="contact-grid">
        <div class="bg-light">
          <h1 class="p-tb-sm">Still Have a Question?</h1>
          <p
            class="text__md"
          >Please don't hesitate to reach out if you need some help or can't find information you are looking for on our website. Our office hours are Monday - Friday 8AM - 5PM CST.</p>
          <div class="contact-grid--options mt-15">
            <div>
              <h3>Call</h3>
              <a href="tel: 877-350-0350">(877) 350-0350</a>
              <h3>Fax</h3>
              <p>(636) 536-9890</p>
            </div>
            <div>
              <h3>Email</h3>
              <p class="text__bold">General:</p>
              <a
                href="mailto: servicing@fbhl.com"
                rel="noindex nofollow"
                name="Email Servicing"
              >servicing@fbhl.com</a>
              <p class="text__bold">Insurance:</p>
              <a href="mailto: insurance@fbhl.com">insurance@fbhl.com</a>
            </div>
          </div>
        </div>
        <div class="bg-green">
          <h3 class="text__center">Mail</h3>
          <div class="addresses">
            <div>
              <span class="text__bold">General</span>
              <address>
                Flat Branch Mortgage, Inc.
                <br />P.O. Box 843084
                <br />Kansas City, MO 64184-3084
              </address>
            </div>
            <div>
              <span class="text__bold">Overnight / Rush</span>
              <address>
                Flat Branch Mortgage, Inc.
                <br />3400 Buttonwood Drive, Ste A
                <br />Columbia, MO 65201
              </address>
            </div>
          </div>
        </div>
      </div>
    </section>
  </section>
</template>

<script>
export default {
  metaInfo: {
    title: "Get In Touch | Flat Branch Servicing"
  }
};
</script>

<style scoped lang="scss">
.contact-banner {
  background: linear-gradient(
      rgba(234, 244, 229, 0.7),
      rgba(234, 244, 229, 0.7)
    ),
    url("https://cdn.fbhl.dev/servicing/images/the-difference-between-prequalification-and-preapproval-featured-image.png");
  @include bg-settings();
}
.padding__md {
  padding: 100px 0;
}
.contact-grid {
  @include grid($cols: 1fr);
}
.bg-light {
  background-color: rgba(167, 211, 211, 0.7);
  padding: 30px;
}
.contact-grid--options {
  @include grid($cols: 1fr 1fr, $col-gap: 15px, $just: center);
  > * {
    text-align: center;
  }
  > div {
    @include grid($cols: 1fr, $row-gap: 10px);
  }
}
.bg-green {
  background-color: $green;
  padding: 25px;
  * {
    color: #fff;
  }
  h3 {
    margin-bottom: 25px;
  }
  .addresses {
    @include grid($cols: 1fr, $row-gap: 15px, $col-gap: 15px, $just: center);
  }
}
@media (min-width: $sm) {
  .bg-green .addresses {
    grid-template-columns: 1fr 1fr;
  }
}
@media (min-width: $lg) {
  .contact-grid {
    grid-template-columns: 2fr 1fr;
  }
  .bg-green .addresses {
    grid-template-columns: 1fr;
  }
}
</style>