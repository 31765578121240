<template>
  <div class="standard-nav flex__between">
    <NavList />
  </div>
</template>

<script>
import NavList from "./NavList";
export default {
  components: {
    NavList
  }
};
</script>

<style scoped lang="scss">
.standard-nav {
  display: none;
}
@media (min-width: $lg) {
  .standard-nav {
    display: block;
  }
}
</style>