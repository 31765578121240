<template>
  <a :href="link" :name="linkName" class="pointer pos__rel" target="_blank" rel="noreferrer">
    <div class="icon">
      <font-awesome-icon icon="file-download" size="lg" />
    </div>
    <h4>{{ name }}</h4>
    <p>{{ content }}</p>
  </a>
</template>

<script>
export default {
  props: ["name", "content", "link"],
  computed: {
    linkName() {
      return `${this.name} download link`;
    }
  }
};
</script>

<style scoped lang="scss">
a {
  text-decoration: none;
  border: 2px solid $cloud;
  border-radius: 2px;
  background-color: $powder;
  padding: 20px 25px;
  margin: 5px 0;
  @include flex($dir: column, $just: space-between);
  p {
    margin: 10px 0;
  }
  * {
    color: $pine;
  }
}
.icon {
  @include pos-abs($pos: absolute, $top: 10px, $left: null, $right: 10px);
}
a:hover {
  background-color: lighten($powder, 5%);
}
</style>