import Vue from 'vue'
import App from './App.vue'
import router from "./router";
import store from "./store";

Vue.config.productionTip = false

//stylesheets
import "./static/styles/main.scss";

//plugins
import "./plugins/meta";
import "./plugins/fontawesome";
import "./plugins/scrollreveal";
import "./plugins/smoothscroll";

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app');
