import { render, staticRenderFns } from "./MobileApp.vue?vue&type=template&id=08a98244&scoped=true&"
var script = {}
import style0 from "./MobileApp.vue?vue&type=style&index=0&id=08a98244&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "08a98244",
  null
  
)

export default component.exports