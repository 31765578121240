<template>
  <div id="app">
    <section class="content">
      <Header />
      <!-- <NewFAQ /> -->
      <router-view></router-view>
      <Footer />
      <Popup
        v-if="getPopupToggled"
        msg="Servicing digital will be available July 6th, 2021 at 5:00 pm CST"
      />
    </section>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import Header from "./components/Parts/Header";
// import NewFAQ from "./components/Parts/NewFAQ";
import Footer from "./components/Parts/Footer/Index";
import Popup from "./components/Parts/Popup";
export default {
  name: "App",
  components: {
    Header,
    // NewFAQ,
    Footer,
    Popup
  },
  computed: {
    ...mapGetters(["getMenuToggled", "getPopupToggled"])
  },
  methods: {
    ...mapActions(["startSetMenuToggled"])
  },
  watch: {
    getMenuToggled(newVal) {
      newVal
        ? document.body.classList.add("no-scroll")
        : document.body.classList.remove("no-scroll");
    }
  },
  mounted() {
    window.addEventListener("resize", () => {
      if (window.innerWidth > 992) {
        this.startSetMenuToggled(false);
      }
    });
  }
};
</script>
