<template>
  <div>
    <div class="btn btn__green btn__large footer-btn" @click="toggle">
      Payments
      <font-awesome-icon :icon="icon" size="1x" class="ml-10" />
    </div>
    <div class="footer-slide__grid" :class="toggleClass">
      <a
        href="https://loansphereservicingdigital.bkiconnect.com/fbhl"
        name="Servicing digital web page"
        rel="noreferrer"
        target="blank"
      >Make a Payment</a>
      <router-link to="/payment-options">Payment Options</router-link>
      <router-link to="/payment-assistance">Payment Assistance</router-link>
      <a
        href="https://form.jotform.com/92305442301141"
        name="Payoff request form"
        rel="noreferrer"
        target="_blank"
      >Payoff Request Form</a>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isToggled: false
    };
  },
  computed: {
    icon() {
      return this.isToggled ? "caret-up" : "caret-down";
    },
    toggleClass() {
      return this.isToggled ? "footer-slide" : null;
    }
  },
  methods: {
    toggle() {
      return (this.isToggled = !this.isToggled);
    }
  }
};
</script>

<style scoped lang="scss">
/* .btn {
  justify-content: space-between !important;
  width: 100%;
}
.footer-slide__grid {
  overflow: hidden;
  @include grid($cols: 1fr, $row-gap: 8px);
  a {
    background-color: $medallion;
    color: $black;
    padding: 5px;
    text-align: center;
    border-radius: 3px;
    text-decoration: none;
  }
  height: auto;
  max-height: 0;
  transition: all 0.25s ease-in-out;
}
.footer-slide {
  margin-top: 8px;
  max-height: 300px;
} */
</style>