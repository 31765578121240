<template>
  <section>
    <div class="covid-banner">
      <div class="container">
        <h1 class="text__center">Flat Branch Mortgage's Response to COVID-19</h1>
        <p class="text__lg text__center margin__md">
          For those experiencing hardship due to COVID-19, Flat Branch Mortgage, Inc. is here to help.
          <br />Please read all of the following information to fully understand your options.
        </p>
      </div>
    </div>
    <section class="container">
      <div class="margin__md">
        <h2>How do I cancel my monthly automatic payments?</h2>
        <ul>
          <li>
            Please go to
            <router-link to="/forms">forms</router-link>&nbsp;to complete an ACH stop form
          </li>
          <li>
            You may also call
            <a href="tel: 877-350-0350">(877) 350-0350</a> to speak with Customer Service and a form can be mailed, emailed or faxed to you
          </li>
          <li>
            Send the completed ACH Stop form to
            <a
              href="mailto: servicing@fbhl.com"
              rel="noindex nofollow"
              name="Email Servicing"
            >servicing@fbhl.com</a>. In the subject line just notate: CANCEL ACH
          </li>
        </ul>
        <p>
          <strong>
            <em>**Please send the ACH Stop form at least 2 business days prior to your scheduled ACH, as it may be too late for us to cancel if not given enough notice**</em>
          </strong>
        </p>
      </div>
      <div class="margin__md">
        <h2>What assistance is available to me?</h2>
        <p>
          Flat Branch Mortgage is offering a 90-day forbearance plan, which means you will not have to pay your mortgage during this time frame.
          At the end of the 90 days, a Loss Mitigation Specialist will contact you to review your mortgage and walk you through what steps are available.
          There are many different options available per the CARES Act if your hardship still exists past the initial 90 days.
          Our specialists are here to help you find the best solution.
        </p>
      </div>
      <div class="margin__md">
        <h2>What is a forbearance plan?</h2>
        <p>
          A forbearance plan is a suspension of payments that will become due at the end of 90 days.
          Forbearance plans can be extended past the initial 90 days based on your individual needs.
          Once your forbearance plan concludes, a review of your hardship and specific loan details will be completed.
          At that time, a Loss Mitigation Specialist will provide you with all the additional options that are available for the repayment or deferral of the suspended payments.
        </p>
      </div>
      <div class="margin__md">
        <h2>What do I need to do?</h2>
        <p>
          Please retain your 90-day forbearance documentation and this FAQ as confirmation that your loan has been updated with COVID-19 status.
          If you have any further questions, please feel free to email us directly at
          <a
            href="mailto: collections@fbhl.com"
          >collections@fbhl.com</a> or call Customer Service at
          <a href="tel: 877-350-0350">(877) 350-0350</a>.
          The customer service team will get you in touch with the first available Loss Mitigation Specialist.
        </p>
      </div>
      <div class="margin__md">
        <h2>How will a forbearance plan impact my credit?</h2>
        <p>
          During the forbearance period, monthly payments are not required.
          Adverse credit reporting and late charges will not occur during this period.
          This change will remain in effect until a permanent loss mitigation option has been completed.
        </p>
      </div>
      <div class="margin__md">
        <h2>When will I need to repay the payments due at the end of my forbearance period?</h2>
        <p>
          Any suspended payments will be due at the end of the forbearance period.
          A Loss Mitigation Specialist will contact you around 30 days before the end of the agreement to discuss alternative options.
          We do realize paying all of the suspended payments at once can be hard and will make sure we look at all options available for repayment.
        </p>
      </div>
      <div class="margin__md">
        <h2>Can I still make payments if I am granted a forbearance?</h2>
        <p>
          Yes.
          If you can make a partial or full payment, it is recommended that you do so, even though your forbearance was granted.
        </p>
        <p>
          <strong>
            <em>**Please note that any partial payments will be held in an unapplied bucket until a full payment can be posted to your account**</em>
          </strong>
        </p>
      </div>
      <div class="margin__md">
        <h2>Why am I receiving a monthly billing statement during my forbearance period?</h2>
        <p>
          To meet compliance requirements, Flat Branch Mortgage will continue to send you a monthly billing statement for your records.
          You are not required to make any payments while you are in an active forbearance agreement.
        </p>
        <p>
          <strong>
            <em>**Reminder, there will not be any negative credit reporting or late charge assessments during this time period**</em>
          </strong>
        </p>
      </div>
    </section>
    <div class="covid-notice">
      <div class="container">
        <h2>Notice</h2>
        <p>Please be aware of scams offering payment assistance options. There are no fees charged to you to complete a forbearance plan.</p>
        <p>If you suspect fraud, please contact us immediately to confirm you are speaking with a representative of Flat Branch Mortgage, Inc.</p>
        <p>
          Click
          <router-link to="/payment-assistance#scam" name="information about foreclosure scams">here</router-link>&nbsp;for more information on what to watch out for and how to report a scam.
        </p>
        <p>As always, we will continue to devote all necessary resources to help you and maintain the best service possible.</p>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  metaInfo: {
    title: "Covid-19 Information | Flat Branch Servicing"
  }
};
</script>

<style scoped lang="scss">
.covid-banner {
  background: linear-gradient(
      rgba(167, 211, 211, 0.8),
      rgba(167, 211, 211, 0.8)
    ),
    url("https://cdn.fbhl.dev/servicing/images/our-response-to-hardship-during-coronavirus-featured-image.jpg");
  @include bg-settings();
  padding: 150px 0;
  * {
    color: $pine;
  }
}
h2 {
  margin: 20px 0;
}
ul {
  padding-left: 15px;
}
.margin__md p {
  padding-left: 15px;
}
ul,
p {
  font-size: 1.2rem;
}
.covid-notice {
  background-color: $orange;
  padding: 100px 0;
  p {
    margin-bottom: 15px;
  }
  :not(a) {
    color: #fff;
  }
  a {
    color: $black;
    font-weight: bold;
  }
}
</style>