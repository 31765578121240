export default [
   {
      type: "billing",
      q: `When should I receive my mortgage statement?`,
      a: `<div>
      <p>
         Monthly mortgage statements are processed under the bill & receipt method. Once your monthly payment is applied to your loan, a statement will generate within the next 3-5 business days and be in the mail soon after. You are able to view your statement through <i>Servicing Digital</i> online or through the mobile app at any time
      <p>
      <p>
         <span class="text__green text__bold">Go Green with <i>Servicing Digital</i>!</span>
         <br>
         <span>Statements online are not only better for the environment, they are also the fastest and most secure way to receive your monthly account statement.</span>
         <br>
         <span>
         Sign up for online statements today by going to the Document Center and updating your Delivery Preferences to Paperless Statements.
      </span>
      </p>
      </div>`
   },
   {
      type: "billing",
      q: `Can I pay with credit / debit card?`,
      a: `<p >At this time, Flat Branch Mortgage, Inc. is able to process payments using either a checking or savings account. Please have your bank account information available for reference. Flat Branch Mortgage, Inc. does not process payments with a credit/debit card.</p>`
   },
   {
      type: "billing",
      q: `Will you accept a payment less than the total amount due?`,
      a: `<div><p>
            Flat Branch Mortgage, Inc. cannot apply payments received that are less than the total amount due. Partial payments will be placed in a suspense account. 
            You will be notified by mail and the funds will be held until the full balance due is received.
         </p>
         <p>
            Contact Customer Service at <a href="tel: 877-350-0350" rel="external nofollow">(877) 350-0350</a> to discuss Flexible Payment Options.
         </p>
         </div>
         `
   },
   {
      type: "billing",
      q: `Do I have a grace period?`,
      a: `<p>Although payments are considered late if not received by the due date, most mortgage notes allow time after the due date for the monthly payment to be received before late charges are assessed. Late Charges are assessed on or after the 17th of the month, and any payment received on or after the 17th day would include the late charge.</p>`
   },
   {
      type: "billing",
      q: `What fees and costs are associated with servicing my loan?`,
      a: `<p>Transparency is a way of life at Flat Branch Mortgage, Inc., and in that spirit here’s our <span><a href="https://cdn.fbhl.dev/servicing/files/fee-and-cost-schedule.pdf" target="_blank" name="complete list of fees and costs download link" rel="noreferrer">complete list of fees and costs</a></span>.</p>`
   },
   {
      type: "escrow",
      q: `What is an escrow account?`,
      a: `<div> <p>An escrow, or impound, account is an account that we maintain as part of your mortgage. The account is used to pay recurring property-related expenses on your behalf. The most common expenses are property taxes and homeowners insurance.</p><p>These expenses are usually semi-annual or annual and can involve large payments. The escrow account allows you to make monthly payments to ensure you have enough money to pay those bills when they come due. The requirement to have an escrow account is determined at closing.</p></div>`
   },
   {
      type: "escrow",
      q: `When will I receive my Escrow Statement?`,
      a: `<p>At least once every 12 months you will receive an Escrow Account Statement. This statement ensures that the monthly escrow payment will be sufficient to pay the amounts anticipated for escrow items.</p>`
   },
   {
      type: "escrow",
      q: `What is included on my Escrow Statement?`,
      a: `<div><p>The Escrow Account Statement will include the following:</p><ul><li>Your new escrow payment</li><li>Projected disbursements and the next due month</li><li>Projections to support the target balance</li><li>Actual transactions since your last analysis</li><li>Flat Branch Mortgage, Inc. requires that all accounts that are escrowed, carry a two (2) month cushion.<ul><li>The amount Flat Branch Mortgage, Inc. requires to be paid into the escrow account to cover unanticipated disbursements. Per federal law, the maximum cushion is equal to 1/6 of the total annual escrow disbursements, unless otherwise specified by state law.</li></ul></li></ul></div>`
   },
   {
      type: "escrow",
      q: `Why did my escrow payment change (increase or decrease)?`,
      a: `<div><p>Changes in your payment can occur for many reasons. Below is a list of reasons why these changes typically occur:</p><strong>Escrow Payment(s)</strong><ul><li>Monthly payment(s) received were less than or greater than expected</li><li>Monthly payment(s) received earlier than expected</li><li>Previous overage returned to escrow</li><li>Previous deficiency/shortage not paid entirely</li><li>Missing payments</li></ul><strong>Homeowner's Insurance</strong><ul><li>Tax rate and/or assessed value changed</li><li>Exemption status lost or changed</li><li>Supplemental/delinquent tax paid</li><li>Paid earlier or later than expected</li><li>Tax installment not paid</li><li>Tax refund received</li><li>New tax escrow requirement paid</li></ul><strong>Property Taxes</strong><ul><li>Premium changed</li><li>Coverage changed</li><li>Additional premium due</li><li>Paid earlier or later than expected</li><li>Premium was not paid</li><li>Premium refund received</li><li>New insurance escrow requirement paid</li><li>Lender-placed insurance premium paid</li></ul></div>`
   },
   {
      type: "escrow",
      q: `Can I remove my escrow account?`,
      a: `<div><p>Yes, if your account is a Fannie Mae or Freddie Mac loan and meets the necessary requirements. For a list of the requirements, please refer to the <span><a href="https://cdn.fbhl.dev/servicing/files/escrow_waiver_request.pdf" target="blank" name="Escrow Waiver Request Form download link" rel="noreferrer">Escrow Waiver Request Form</a></span></p><p>If the escrow related questions do not cover your questions, we invite you to call Customer Service at <a href="tel: 8773500350">(877) 350-0350</a> or <a href="mailto: servicing@fbhl.com" rel="noindex nofollow" name="Email Servicing">servicing@fbhl.com</a></p></div>`
   },
   {
      type: "insurance",
      q: `Does Flat Branch Mortgage, Inc. require insurance coverage on my home?`,
      a: `<p>Your mortgage requires that you maintain insurance on your property and that you notify us in the event you reduce or increase the amount of insurance, terminate it for any reason, or make a change in the agency now insuring your property. You should be certain that the amount of insurance that you carry is sufficient at today's replacement cost.</p>`
   },
   {
      type: "insurance",
      q: `I changed insurance companies. What do I need to do?`,
      a: `<div><p>If you have acquired a new policy (substitution policy) Flat Branch Mortgage, Inc. will pay the new policy from your escrow account. Substitution policies should be provided to us within 30 days prior to the expiration of your existing policy. Please email to <a href="mailto: insurance@fbhl.com">insurance@fbhl.com</a> or mail to <br>Flat Branch Mortgage, Inc.<br>PO Box 843084<br> Kansas City, MO 64184-3084</p><p>If you are in receipt of your hazard or flood policy renewal, please provide us with this information. It can be faxed to (636)-536-9890 or mailed to the address below. You may also provide the documentation via email <a href="mailto: insurance@fbhl.com">insurance@fbhl.com</a>. <br>Flat Branch Mortgage, Inc.<br>PO Box 843084<br> Kansas City, MO 64184-3084</p><p>Refund from previous policy: Please send in any refund you may receive from your previous insurance company. This is to ensure that you escrow account is not short due to paying multiple policies in one year. Please mail a personal check noted as escrow only, as we cannot apply a check from the insurance company to your account, to the following address: <address>Attention: Accounting<br><br>Flat Branch Mortgage, Inc.<br>3400 Buttonwood Drive, Ste A <br>Columbia, MO 65201</address></p></div>`
   },
   {
      type: "insurance",
      q: `When will my homeowners / hazard insurance be paid?`,
      a: `<p>Typically, we will receive a bill from your insurance carrier 30-45 days prior to a policy expiring. Within 7-10 business days after receiving the bill, we will submit payment. Bills received less than 7 days prior to expiration are expedited for payment.</p>`
   },
   {
      type: "insurance",
      q: `What happens when the insurance carrier bills for additional premiums?`,
      a: `<div><p>When we receive an additional premium due for your insurance policy, it will be paid from your escrow account and the next premium due will be increased by the amount billed.</p><p>For example, if your annual premium is $800 and we receive an additional premium due for $20, the additional premium due will be paid and the projected annual premium will be updated to $820.</p></div>`
   },
   {
      type: "insurance",
      q: `What should I do if I get a notice that my insurance is going to be cancelled?`,
      a: `<div><p>Please contact us as quickly as possible at <a href="tel: 8773500350">(877) 350-0350</a> between 8:00 AM and 5:00 PM CST, Monday-Friday.</p><p>The cancellation notice should be faxed to (636) 536-9890 or emailed to <a href="mailto: insurance@fbhl.com">insurance@fbhl.com</a>. Ensure your loan number is on the document.</p></div>`
   },
   {
      type: "insurance",
      q: `What is the Mortgagee  Clause for Flat Branch Mortgage, Inc.?`,
      a: `<div><p>Please ensure that all documentation references your loan number, and that the Mortgagee Clause reads:</p><p>Flat Branch Mortgage, Inc.<br>ISAOA ATIMA<br>P.O. Box 843084<br>Kansas City, MO 64184-3084</p></div>`
   },
   {
      type: "insurance",
      q: `What should I do if my insurance amount is wrong?`,
      a: `<div><p>Please send a copy of the insurance decleration and request to have your account updated. If you would like to have your payment adjusted, please include a written request to have your payment updated to the following address:</p><p>Flat Branch Mortgage, Inc.<br>P.O. Box 843084<br>Kansas City, MO 64184-3084<br><strong>Or email:</strong> <a href="mailto: servicing@fbhl.com" rel="noindex nofollow" name="Email Servicing">servicing@fbhl.com</a></p></div>`
   },
   {
      type: "claims",
      q: `I received a check from my insurance company. What do I do now?`,
      a: `<p>If your home has been damaged, Flat Branch Mortgage, Inc. is here to help you through this devastating time. We are here and understand that time is of the essence to repair your home.</p><p>The following forms will outline all the process to make this simple; if you have questions, please call us at <a href="tel:+18773500350">877.350.0350</a>.</p><p><ul><li><span><a href="https://cdn.fbhl.dev/servicing/files/loss_draft_form-usda.pdf" target="blank" name="Claim Loss Information Form download link" rel="noreferrer">Customer Insurance Loss Draft - USDA Loans</a></span></li><li><span><a href="https://cdn.fbhl.dev/servicing/files/loss_draft_form-other.pdf" target="blank" name="Claim Loss Information Form download link" rel="noreferrer">Customer Insurance Loss Draft - Other Loans</a></span></li></ul></p>`
   },
   {
      type: "claims",
      q: `Once I submit the required documentation, how long does it take to receive funds?`,
      a: `<p>Every situation is different and could contain factors that vary the disbursal timeframes. Typically, once we have the required documentation we can disburse either the entire amount or an initial installment within five business days.</p>`
   },
   {
      type: "taxes",
      q: `I received my Real Estate Tax Notice, what do I need to do?`,
      a: `<div><p>If you are in receipt of the current year’s Real Estate Tax Notice for your property, it is no longer necessary to send your tax bill to Flat Branch Mortgage, Inc.</p><p>Flat Branch Mortgage, Inc. will receive your tax information electronically from the tax office and your tax bill will be paid prior to the due date. You can confirm the tax disbursement from your escrow account by viewing your account on <i>Servicing Digital</i> or by calling Customer Service at <a href="tel: 8773500350">(877) 350-0350</a> or <a href="mailto: servicing@fbhl.com" rel="noindex nofollow" name="Email Servicing">servicing@fbhl.com</a>.</p></div>`
   },
   {
      type: "taxes",
      q: `When will Real Estate Taxes be paid from my escrow account?`,
      a: `<div><p>Your taxes will be paid on or before the due date. If any early payment discounts are provided by taxing authority, then we will make the payment prior to the discount period expiring.</p><p>Disbursements for taxes and insurance can also be viewed online in <i>Servicing Digital</i>. You will be able to see the exact date that the funds were withdrawn and remitted for payment.</p></div>`
   },
   {
      type: "taxes",
      q: `What happens if my Real Estate Taxes are paid late?`,
      a: `<p>If you have an escrow account and believe your taxes have been paid late or you received a bill for penalties from the taxing authority, please call Customer Service at <a href="tel: 8773500350">(877)-350-0350</a>. Any penalties resulting from the taxes paid late by Flat Branch Mortgage, Inc. will be paid by Flat Branch Mortgage, Inc.</p>`
   },
   {
      type: "taxes",
      q: `What should I do if my tax amount is wrong?`,
      a: `<div><p>Please send a copy of the tax bill and request to have your account updated. If you would like to have your payment adjusted, please include a written request to have your payment updated to the following address:</p><p>Flat Branch Mortgage, Inc.<br>P.O. Box 843084<br>Kansas City, MO 64184-3084<br><strong>Or email:</strong> <a href="mailto: servicing@fbhl.com" rel="noindex nofollow" name="Email Servicing">servicing@fbhl.com</a></p></div>`
   },
   {
      type: "year-end",
      q: `When should I receive my 1098, 1099, or Annual Mortgage Statement?`,
      a: `<p>Typically, the 1098, 1099, or Annual Mortgage Statement will be mailed to you by January 31st of each year.</p>`
   },
   {
      type: "year-end",
      q: `If I do not receive my 1098, 1099, or Annual Mortgage Statement, who should I contact?`,
      a: `<div><p>Contact Customer Service at <a href="tel: 8773500350">(877)-350-0350</a> or <a href="mailto: servicing@fbhl.com" rel="noindex nofollow" name="Email Servicing">servicing@fbhl.com</a></p><p>You can also view your 1098, 1099, and Annual Mortgage Statement on <i>Servicing Digital</i> in the Statement/Document tab.</p></div>`
   },
   {
      type: "payoff",
      q: `How do I submit a Payoff Statement?`,
      a: `<div><p>You can run a payoff quote or generate an actual payoff statement yourself through <i>Servicing Digital</i>. No need to contact us, as this will notify us of your upcoming payoff and give you everything you need. Payoff requests can be run up to 30 days out.</p><p>You can also submit in writing to the below mailing address, fax number or e-mail address. Please do not mail any payoff request to the Post Office box, as this may delay our response time</p><p>Attention: Payoff Department<br>Flat Branch Mortgage, Inc.<br>140 Long Road Suite 200 <br>Chesterfield, MO 63005</p><p>Fax: (636) 536-9890<br>E-mail: <a href="servicing@fbhl.com">servicing@fbhl.com</a></p><p>If you are considering paying off your mortgage, please let us know of any change in mailing address for future mailings, such as applicable refunds, tax statements, etc.</p></div>`
   },
   {
      type: "payoff",
      q: `When will I get my refund from the payoff?`,
      a: `<p>Refund Check will be mailed to you within twenty (20) days of full payoff amount and will be sent to the address on file for your mortgage statement. If an alternate address needs to be used, please submit a <span><a href="https://cdn.fbhl.dev/servicing/files/change_of_information.pdf" target="blank" name="Change of Information Form download link">Change of Information Form</a></span></p>`
   },
   {
      type: "scra",
      q: `What should I do if my military status changes?`,
      a: `<div><p>Please call Customer Service at <a href="tel: 8773500350">(877)-350-0350</a> or email <a href="servicing@fbhl.com">servicing@fbhl.com</a>. The <span><a href="https://cdn.fbhl.dev/servicing/files/active-military-duty-7-9-20.pdf" target="blank" name="active military status form download link" rel="noreferrer">active military status form</a></span> can be used to notify us of your active status. This can be emailed or mailed to us at</p><p>Flat Branch Mortgage, Inc.<br> PO Box 843084<br> Kansas City, MO 64184-3084</p><p>The Service members Civil Relief Act (SCRA) provides certain relief to Service members and Reservists who are called to active duty. This program is designed to provide assistance to active-duty military personnel, and under certain circumstances, their dependents for financial obligations. Some benefits may include, but are not limited to: interest rate reductions and imposed stay that may prevent actions, such as repossession or foreclosure.</p></div>`
   },
   {
      type: "general",
      q: `I need to change the contact information on my account, how do I do this?`,
      a: `<div><p>You can update your contact information (mailing address, phone number, and email) directly through <i>Servicing Digital</i>. You can also update your information by submitting a <span><a href="https://cdn.fbhl.dev/servicing/files/change_of_information.pdf" target="blank" name="Change of Information Form download link" rel="noreferrer">Change of Information Form</a></span> form. Below is a list of other applicable changes and any documentation you may need.</p><ul><li>Divorce: If titleholders are divorced, please attach a copy of the recorded divorce decree.</li><li>Death: If either titleholder is now deceased, please attach a copy of the death certificate. Also, please provide any information on the estate or will. If you are requesting your name added to the Loan, this information must be provided.</li><li>Marriage: If marriage has occurred, please attach a copy of marriage license.</li><li>Name Change: If there has been a legal name change, please attach a copy of the recorded document evidencing the change.</li></ul></div>`
   },
   {
      type: "general",
      q: `I need to give authorization for my spouse to discuss my loan with Flat Branch Mortgage, Inc., what do I need to submit?`,
      a: `<div><p>If you wish to grant a third party access to your account, you will need to complete the <span><a href="https://cdn.fbhl.dev/servicing/files/borrower_third_party_auth_form.pdf" target="blank" name="Third Party Authorization Form download link" rel="noreferrer">Third Party Authorization Form</a></span> and email to <a href="servicing@fbhl.com">servicing@fbhl.com</a> or mail to</p><p>Flat Branch Mortgage, Inc.<br>PO Box 843084, <br>Kansas City, MO 64184-3084</p></div>`
   },
   {
      type: "general",
      q: `What if there is an error or I want to dispute information on my account?`,
      a: `<div><p>You have the right to request and receive a written explanation within: 1) seven (7) days for a payoff quote we provided in error: 2) ten (10) days for information concerning the owner or assignee of your mortgage: 3) thirty (30) days for general information about your loan. All such requests must be sent in writing and should be sent to:</p><p>Flat Branch Mortgage, Inc.<br>PO Box 843084, <br>Kansas City, MO 64184-3084</p><p>General inquiries should be mailed separately from your mortgage payment to our correspondence address. Be sure to include your loan number and telephone number, including area code, on all inquiries.</p><p>Notify us at the above address if you believe any information we have reported or may report to a credit bureau about your loan that is inaccurate.</p><p>Learn more about your rights by contacting Flat Branch Mortgage, Inc., or visiting the <a href="https://www.consumerfinance.gov/" rel="noreferrer" target="blank">Consumer Financial Protection Bureau</a>.</p></div>`
   }
]
