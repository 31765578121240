import questions from "../data/questions.js";

const state = {
   menuToggled: false,
   popupToggled: false,
   questions
};

const getters = {
   getMenuToggled(state) {
      return state.menuToggled
   },
   getQuestions(state) {
      return state.questions;
   },
   getPopupToggled(state) {
      return state.popupToggled;
   }
};

const actions = {
   startSetMenuToggled({ commit }, bool) {
      commit("SET_MENU_TOGGLED", bool);
   },
   startSetPopupToggled({ commit }, bool) {
      commit("SET_POPUP_TOGGLED", bool);
   }
};

const mutations = {
   SET_MENU_TOGGLED(state, bool) {
      return state.menuToggled = bool;
   },
   SET_POPUP_TOGGLED(state, bool) {
      return state.popupToggled = bool;
   }
};

export default {
   state,
   getters,
   actions,
   mutations
}