<template>
  <section class="margin__sm">
    <section class="container">
      <h2 class="padding__sm">Billing & Payment Processing</h2>
      <Question
        v-for="question in billingQuestions"
        :key="question.q"
        :question="question.q"
        :answer="question.a"
      />
      <h2 class="padding__sm">Escrow</h2>
      <Question
        v-for="question in escrowQuestions"
        :key="question.q"
        :question="question.q"
        :answer="question.a"
      />
      <h2 class="padding__sm">Insurance</h2>
      <Question
        v-for="question in insuranceQuestions"
        :key="question.q"
        :question="question.q"
        :answer="question.a"
      />
      <h2 class="padding__sm">Insurance Claims & Loss Draft Checks</h2>
      <Question
        v-for="question in claimsQuestions"
        :key="question.q"
        :question="question.q"
        :answer="question.a"
      />
      <h2 class="padding__sm">Taxes</h2>
      <Question
        v-for="question in taxesQuestions"
        :key="question.q"
        :question="question.q"
        :answer="question.a"
      />
      <h2 class="padding__sm">Year End Tax Reporting</h2>
      <Question
        v-for="question in yearEndQuestions"
        :key="question.q"
        :question="question.q"
        :answer="question.a"
      />
      <h2 class="padding__sm">Payoff Information</h2>
      <Question
        v-for="question in payoffQuestions"
        :key="question.q"
        :question="question.q"
        :answer="question.a"
      />
      <h2 class="padding__sm">SCRA</h2>
      <Question
        v-for="question in scraQuestions"
        :key="question.q"
        :question="question.q"
        :answer="question.a"
      />
      <h2 class="padding__sm">General Account Information</h2>
      <Question
        v-for="question in generalQuestions"
        :key="question.q"
        :question="question.q"
        :answer="question.a"
      />
    </section>
  </section>
</template>

<script>
import { mapGetters } from "vuex";
import Question from "./Question";
export default {
  metaInfo: {
    title: "FAQ | Flat Branch Servicing"
  },
  components: {
    Question
  },
  computed: {
    ...mapGetters(["getQuestions"]),
    billingQuestions() {
      return this.getQuestions.filter(item => item.type === "billing");
    },
    escrowQuestions() {
      return this.getQuestions.filter(item => item.type === "escrow");
    },
    insuranceQuestions() {
      return this.getQuestions.filter(item => item.type === "insurance");
    },
    claimsQuestions() {
      return this.getQuestions.filter(item => item.type === "claims");
    },
    taxesQuestions() {
      return this.getQuestions.filter(item => item.type === "taxes");
    },
    yearEndQuestions() {
      return this.getQuestions.filter(item => item.type === "year-end");
    },
    payoffQuestions() {
      return this.getQuestions.filter(item => item.type === "payoff");
    },
    scraQuestions() {
      return this.getQuestions.filter(item => item.type === "scra");
    },
    generalQuestions() {
      return this.getQuestions.filter(item => item.type === "general");
    }
  }
};
</script>

<style scoped lang="scss">
</style>