import Home from "./Home";
import Covid from "./Covid";
import SystemFAQ from "./SystemFAQ";
import NewCust from "./NewCust";
import PaymentOpts from "./PaymentOpts";
import PaymentAsst from "./PaymentAsst";
import Forms from "./Forms";
import FAQ from "./FAQ";
import Contact from "./Contact";
import FCRA from "./FairCredit";
import ESign from "./ESignContent";
import EDeliver1098 from "./1098EDeliver";
import RecurACH from "./RecurACH";
import OneTimeACH from "./OneTimeACH";

export default {
   Home,
   Covid,
   SystemFAQ,
   NewCust,
   PaymentOpts,
   PaymentAsst,
   Forms,
   FAQ,
   Contact,
   FCRA,
   ESign,
   EDeliver1098,
   RecurACH,
   OneTimeACH
}
